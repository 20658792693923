import {
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { MRT_EditActionButtons } from "material-react-table";
import { useContext, useState } from "react";

import { DataContext } from "../../../contexts/dataContext";
import TYPE_STRINGS from "../../../static/constants/TYPE_STRINGS";
import currency from "../../../static/constants/currency.json";
import iso2_currency from "../../../static/constants/iso2_currency.json";
import systems_of_measurement from "../../../static/constants/systems_of_measurement";
import { handleKey } from "../../utils";

/**
 *
 * @param {Object} param0
 * @param {import("material-react-table").MRT_TableInstance<never>} table MRT Table isntance
 * @param {import("material-react-table").MRT_Row<never>} row
 * @param {[]} countries
 * @param {JSON} organizationLookup map of organization_ids to organization names
 * @param {JSON} validationErrors
 * @param {React.Dispatch<React.SetStateAction<JSON>>} setValidationErrors
 * @param {Boolean} isAddView true= add project, false= edit project
 */
export default function ProjectDialog({
  table,
  row,
  countries,
  validationErrors,
  setValidationErrors,
  isAddView,
}) {
  const [regionalValues, setRegionalValues] = useState({
    currency_code: row.original.currency_code,
    unit: row.original.unit,
  });

  const { organizationMemo } = useContext(DataContext);

  const project_types = TYPE_STRINGS.PROJECT_TYPE;

  function handleChange(e) {
    e.preventDefault();
    row._valuesCache[e.target.name] = e.target.value;
  }

  const defaultTextFieldProps = (field) => ({
    name: field,
    id: field,
    fullWidth: true,
    error: !!validationErrors[field],
    helperText: validationErrors[field],
    defaultValue: row.original[field],
    onFocus: () => setValidationErrors({ ...validationErrors, [field]: false }),
  });

  const defaultFormControlProps = (field) => ({
    fullWidth: true,
    error: !!validationErrors[field],
    variant: "standard",
    required: true,
  });

  const defaultSelectProps = (field) => ({
    id: field,
    name: field,
    value: regionalValues[field],
    onChange: (e) => {
      handleChange(e);
      setRegionalValues((prev) => ({
        ...prev,
        [field]: e.target.value,
      }));
    },
    MenuProps: { PaperProps: { sx: { maxHeight: "60%" } } },
  });

  return (
    <>
      <DialogTitle>{isAddView ? "Create" : "Edit"} Project</DialogTitle>
      <DialogContent>
        {/* if creating a project, allow users to set the organization */}
        {isAddView && (
          <FormControl {...defaultFormControlProps("organization_id")}>
            <InputLabel id="organization-label">Organization</InputLabel>
            <Select
              labelId="organization-label"
              id="organization_selection"
              name="organization_id"
              defaultValue={organizationMemo.selectedOrg}
              disabled={Boolean(organizationMemo.selectedOrg)}
              onChange={handleChange}
              onFocus={(e) =>
                setValidationErrors((prev) => ({
                  ...prev,
                  organization_id: false,
                }))
              }
              MenuProps={{ PaperProps: { sx: { maxHeight: "60%" } } }}
            >
              {Object.entries(organizationMemo.lookup).map(([key, label]) => (
                <MenuItem key={key} value={key} onKeyDown={handleKey}>
                  {label}
                </MenuItem>
              ))}
            </Select>
            {!!validationErrors.organization_id && (
              <FormHelperText>No Selection</FormHelperText>
            )}
          </FormControl>
        )}
        {/* below fields are present in both add/edit project */}
        <TextField
          {...defaultTextFieldProps("name")}
          type="text"
          label="Name"
          autoFocus
          required
          onChange={handleChange}
        />
        <TextField
          type="text"
          {...defaultTextFieldProps("description")}
          multiline
          label="Description"
          onChange={handleChange}
        />
        <FormControl {...defaultFormControlProps("type")}>
          <InputLabel id="type-label">Type</InputLabel>
          <Select
            labelId="type-label"
            id="type"
            name="type"
            defaultValue={row.original.type}
            onChange={handleChange}
            onFocus={(e) =>
              setValidationErrors((prev) => ({ ...prev, type: false }))
            }
          >
            {Object.entries(project_types).map(([key, value]) => (
              <MenuItem key={key} value={key} onKeyDown={handleKey}>
                {value}
              </MenuItem>
            ))}
          </Select>
          {!!validationErrors.type && (
            <FormHelperText>No Selection</FormHelperText>
          )}
        </FormControl>
        <FormControl {...defaultFormControlProps("country_code")}>
          <InputLabel id="country-label">Country</InputLabel>
          <Select
            labelId="country-label"
            id="country_selection"
            name="country_code"
            defaultValue={row.original.country_code}
            onChange={(e) => {
              handleChange(e);
              row._valuesCache.currency_code =
                regionalValues.currency_code || iso2_currency[e.target.value];
              row._valuesCache.unit =
                regionalValues.unit || (e.target.value == "US" ? 2 : 1);
              setRegionalValues({
                // auto-sets the currency code (if it hasn't already been set) based on country's iso2
                currency_code:
                  regionalValues.currency_code || iso2_currency[e.target.value],
                //auto-sets the unit of measurement (if it hasn't already been set), 1 for "Metric", 2 for "Imperial" (of the countries that will see this page, only USA uses Imperial),
                unit: regionalValues.unit || (e.target.value == "US" ? 2 : 1),
              });
            }}
            onFocus={(e) =>
              setValidationErrors((prev) => ({ ...prev, type: false }))
            }
            MenuProps={{ PaperProps: { sx: { maxHeight: "60%" } } }}
          >
            {countries.map((country) => (
              <MenuItem
                key={country.iso2}
                value={country.iso2}
                onKeyDown={handleKey}
              >
                {country.name}
              </MenuItem>
            ))}
          </Select>
          {!!validationErrors.country_code && (
            <FormHelperText>No Selection</FormHelperText>
          )}
        </FormControl>
        <FormControl {...defaultFormControlProps("currency_code")}>
          <InputLabel id="currency-label">Currency</InputLabel>
          <Select
            {...defaultSelectProps("currency_code")}
            labelId="currency-label"
          >
            {Object.entries(currency).map(([key, value]) => (
              <MenuItem key={key} value={key} onKeyDown={handleKey}>
                {value.name}
              </MenuItem>
            ))}
          </Select>
          {!regionalValues.currency_code &&
            !!validationErrors.currency_code && (
              <FormHelperText>No Selection</FormHelperText>
            )}
        </FormControl>
        <FormControl {...defaultFormControlProps("unit")}>
          <InputLabel id="measurement-label">Units</InputLabel>
          <Select {...defaultSelectProps("unit")} labelId="measurement-label">
            {Object.entries(systems_of_measurement).map(([key, value]) => (
              <MenuItem key={key} value={key} onKeyDown={handleKey}>
                {value}
              </MenuItem>
            ))}
          </Select>
          {!regionalValues.unit && !!validationErrors.unit && (
            <FormHelperText>No Selection</FormHelperText>
          )}
        </FormControl>
      </DialogContent>
      <DialogActions>
        <MRT_EditActionButtons variant="text" table={table} row={row} />
      </DialogActions>
    </>
  );
}
