import MaterialTable, { MTableToolbar } from "@material-table/core";
import EditIcon from "@mui/icons-material/Edit";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { useContext, useEffect, useMemo, useState } from "react";
import { Navigate } from "react-router-dom";
import { DataContext } from "../contexts/dataContext";
import { SnackBarContext } from "../contexts/snackBarContext";
import TYPE_STRINGS from "../static/constants/TYPE_STRINGS";
import {
  chargerURL,
  emissionsURL,
  vehicleURL,
} from "../static/constants/backendRoutes";
import UseAuth from "./auth/useAuth";
import MasterDataDialogs from "./dialogs/masterDataDialogs";
import MasterDataEmissionsDialog from "./dialogs/masterDataEmissionsDialog";
import { getMasterData, Icons, unitWrapper } from "./utils";

export default function MasterData() {
  const { accessRights } = useContext(DataContext);
  const [vehicles, setVehicles] = useState([]);
  const [chargers, setChargers] = useState([]);
  const [emissions, setEmissions] = useState([]);
  const [resetToggle, setResetToggle] = useState(true);
  const [error, setError] = useState(false);
  /**
   * @type {[{dialogView: Number, modelState: (Object|undefined)}]}
   * dialogView: 0 for no dialog, 1 for vehicle model, 2 for charger, 3 for emission
   * modelState: the initial state of the model to edit, undefined for create new */
  const [dialogState, setDialogState] = useState({
    dialogView: 0,
    modelState: undefined,
  });
  /** 0 for unknown/loading, 1 for vehicle model chart, 2 for charger model table, 3 for emissions table*/
  const [tableDisplay, setTableDisplay] = useState(1);

  const { snackBarElement } = useContext(SnackBarContext);

  useEffect(() => {
    /**
     * fetches and stores data on the currently signed in user,
     * their vehicle, and their chargers,
     * for use verifying the user is a member of staff,
     * and auto-filling certain fields
     */
    function fetchData() {
      if (!UseAuth("get")) {
        window.location.assign("/login"); ///TODO: should display error page
        return;
      }

      const headers = {
        Authorization: `Token ${UseAuth("get")}`,
        "Content-Type": "application/json",
      };

      getMasterData(snackBarElement, {
        all_info: true,
        onSuccess: setVehicles,
        onError: () => setError(true),
      });

      getMasterData(snackBarElement, {
        is_chargers: true,
        onSuccess: setChargers,
        onError: () => setError(true),
      });

      //fetches and sets emissions data
      fetch(`${emissionsURL}?depot_id=all`, { method: "GET", headers: headers })
        .then((response) => {
          if (response.ok) {
            return response.json().then(({ data }) => {
              setEmissions(data);
            });
          } else {
            errorHandler(
              response,
              snackBarElement,
              "Error retrieving Emissions Models"
            );
            setEmissions([]);
            setError(true);
          }
        })
        .catch((error) => {
          snackBarElement?.current?.displayToast(
            "Error retrieving Emissions Models",
            "error"
          );
          console.log(error);
          setError(true);
        });
    }

    if (accessRights.master_data.create) fetchData();
  }, [accessRights.master_data.create]); //fires on render, and reset data

  /**
   * opens the edit/add dialogs for a model
   * @param {String} dialogView - 1 for vehicle, 2 for charger, 3 for emission
   * @param {(Object|undefined)} modelState the values to be edited, if any (must contain the model ID if editing)
   */
  function handleModelDialogOpen(dialogView, modelState) {
    setDialogState({ dialogView, modelState });
  }

  /**
   * @type {import("@material-table/core").Column<never>[]}
   * used to format the "All existing vehicles" table
   */
  const vehicleColumns = [
    // { title: "ID", field: "id", type: "numeric", editable: "never" },
    { title: "Model", field: "model", editable: "never" },
    {
      title: "Type",
      field: "type",
      type: "numeric",
      lookup: TYPE_STRINGS.VEHICLE_TYPE,
    },
    {
      title: "Size",
      field: "size",
      type: "numeric",
      render: (rowData) =>
        rowData.type == 1 ? (
          <>
            {rowData.size} {unitWrapper("ft")}
          </>
        ) : rowData.type == 4 ? (
          //if selected vehicle type is a schoolbus, display Vehicle Type Character instead
          `Type ${rowData.size}`
        ) : (
          `Class ${rowData.size}`
        ),
    },
    {
      title: "Vehicle Length",
      field: "vehicle_length",
      type: "numeric",
      render: (rowData) => (
        <>
          {rowData.vehicle_length} {unitWrapper("ft")}
        </>
      ),
    },
    {
      title: "Vehicle Width",
      field: "vehicle_width",
      type: "numeric",
      render: (rowData) => (
        <>
          {rowData.vehicle_width} {unitWrapper("ft")}
        </>
      ),
    },
    {
      title: "Vehicle Height",
      field: "vehicle_height",
      type: "numeric",
      render: (rowData) => (
        <>
          {rowData.vehicle_height} {unitWrapper("ft")}
        </>
      ),
    },
    {
      title: "Efficiency",
      field: "efficiency",
      type: "numeric",
      render: (rowData) => (
        <>
          {rowData.efficiency} {unitWrapper("kWh/mi")}
        </>
      ),
    },
    {
      title: "Battery Capacity",
      field: "battery_capacity",
      type: "numeric",
      render: (rowData) => (
        <>
          {rowData.battery_capacity} {unitWrapper("kWh")}
        </>
      ),
    },
    {
      title: "Min. State of Charge",
      field: "min_soc",
      type: "numeric",
      render: (rowData) => (
        <>
          {rowData.min_soc} {unitWrapper("%")}
        </>
      ),
    },
    {
      title: "Max. State of Charge",
      field: "max_soc",
      type: "numeric",
      render: (rowData) => (
        <>
          {rowData.max_soc} {unitWrapper("%")}
        </>
      ),
    },
    {
      title: "Max. Charge Rate at Constant Power",
      field: "max_c_rate_cp",
      type: "numeric",
      render: (rowData) => (
        <>
          {rowData.max_c_rate_cp} {unitWrapper("kW")}
        </>
      ),
    },
    {
      title: "Max. Charge Rate at Constant Voltage",
      field: "max_c_rate_cv",
      type: "numeric",
      render: (rowData) => (
        <>
          {rowData.max_c_rate_cv} {unitWrapper("kW")}
        </>
      ),
    },
    {
      title: "Constant Power to Constant Voltage",
      field: "cp_to_cv",
      type: "numeric",
      render: (rowData) => (
        <>
          {rowData.cp_to_cv} {unitWrapper("%")}
        </>
      ),
    },
    {
      title: "Charging Efficiency",
      field: "charging_efficiency",
      type: "numeric",
      render: (rowData) => (
        <>
          {rowData.charging_efficiency} {unitWrapper("%")}
        </>
      ),
    },
    { title: "Number of Doors", field: "number_of_doors", type: "numeric" },
    {
      title: "Door Height",
      field: "door_height",
      type: "numeric",
      render: (rowData) => (
        <>
          {rowData.door_height} {unitWrapper("ft")}
        </>
      ),
    },
    {
      title: "Door Width",
      field: "door_width",
      type: "numeric",
      render: (rowData) => (
        <>
          {rowData.door_width} {unitWrapper("ft")}
        </>
      ),
    },
    { title: "Coefficient of Drag", field: "cdl", type: "numeric" },
    {
      title: "Coefficient of Rolling Restistance",
      field: "crr",
      type: "numeric",
    },
    {
      title: "Curb Weight",
      field: "curb_weight",
      type: "numeric",
      render: (rowData) => (
        <>
          {rowData.curb_weight} {unitWrapper("Lb")}
        </>
      ),
    },
    {
      title: "Wheel Radius",
      field: "wheel_radius",
      type: "numeric",
      render: (rowData) => (
        <>
          {rowData.wheel_radius} {unitWrapper("m")}
        </>
      ),
    },
    {
      title: "Ventilation Power",
      field: "vent_power",
      type: "numeric",
      render: (rowData) => (
        <>
          {rowData.vent_power} {unitWrapper("kW")}
        </>
      ),
    },
    {
      title: "Auxiliary Power",
      field: "aux_power",
      type: "numeric",
      render: (rowData) => (
        <>
          {rowData.aux_power} {unitWrapper("kW")}
        </>
      ),
    },
    {
      title: "Passenger Capacity",
      field: "passenger_capacity",
      type: "numeric",
    },
    {
      title: "Payload Capacity",
      field: "payload_capacity",
      type: "numeric",
      render: (rowData) => (
        <>
          {rowData.payload_capacity} {unitWrapper("Lb")}
        </>
      ),
    },
    { title: "BEV Cost", field: "bev_veh_cost", type: "currency" },
    { title: "ICE Cost", field: "ice_veh_cost", type: "currency" },
    { title: "Propane Cost", field: "prop_veh_cost", type: "currency" },
    { title: "CNG Cost", field: "cng_veh_cost", type: "currency" },
    { title: "Gasoline Cost", field: "gas_veh_cost", type: "currency" },
    {
      title: "ICE mpg",
      field: "ice_veh_mpg",
      type: "numeric",
      render: (rowData) => (
        <>
          {rowData.ice_veh_mpg} {unitWrapper("mpg")}
        </>
      ),
    },
    {
      title: "Propane mpg",
      field: "prop_veh_mpg",
      type: "numeric",
      render: (rowData) => (
        <>
          {rowData.prop_veh_mpg} {unitWrapper("mpg")}
        </>
      ),
    },
    {
      title: "CNG mpg",
      field: "cng_veh_mpg",
      type: "numeric",
      render: (rowData) => (
        <>
          {rowData.cng_veh_mpg} {unitWrapper("mpg")}
        </>
      ),
    },
    {
      title: "Gasoline mpg",
      field: "gas_veh_mpg",
      type: "numeric",
      render: (rowData) => (
        <>
          {rowData.gas_veh_mpg} {unitWrapper("mpg")}
        </>
      ),
    },
  ];

  /**
   * @type {import("@material-table/core").Column<never>[]}
   * used to format the "all existing chargers" table
   */
  const chargerColumns = [
    { title: "ID", field: "id", hidden: true, editable: "never" },
    { title: "Model", field: "model", editable: "never" },
    {
      title: "Electricity Type",
      field: "electricity_type",
      type: "numeric",
      lookup: TYPE_STRINGS.ELECTRICITY_TYPE,
    },
    {
      title: "Charging Type",
      field: "charging_type",
      type: "numeric",
      lookup: TYPE_STRINGS.CHARGING_TYPE,
    },
    {
      title: "Rating",
      field: "rating",
      type: "numeric",
      render: (rowData) => (
        <>
          {rowData.rating} {unitWrapper("kW")}
        </>
      ),
    },
    { title: "Port Count", field: "number_of_ports", type: "numeric" },
    {
      title: "Efficiency",
      field: "efficiency",
      type: "numeric",
      render: (rowData) => (
        <>
          {rowData.efficiency * 100} {unitWrapper("%")}
        </>
      ),
    },
    { title: "Cost", field: "cost", type: "currency" },
  ];

  /** @type {import("@material-table/core").Column<never>[]} */
  const emissionColumns = [
    {
      title: "ID",
      field: "id",
      editable: "never",
      hidden: true,
    },
    { title: "Country", field: "country", defaultSort: "desc" },
    { title: "State", field: "state", emptyValue: "N/A" },
    {
      title: "Emission",
      field: "emission",
      render: (rowData) => (
        <>
          {rowData.emission} {unitWrapper("kg/kWh")}
        </>
      ),
    },
    {
      title: "NOX",
      field: "nox",
      render: (rowData) => (
        <>
          {rowData.nox} {unitWrapper("g/kWh")}
        </>
      ),
    },
    {
      title: "SO2",
      field: "so2",
      render: (rowData) => (
        <>
          {rowData.so2} {unitWrapper("g/kWh")}
        </>
      ),
    },
  ];

  /**
   * Deletes vehicle row(s), sends backend the names of the vehicle(s) to be deleted
   * @param {String} vehicle_models string of model name to be deleted
   */
  const handleVehicleDelete = (vehicle_models) => {
    const body = { model_list: [vehicle_models] };

    fetch(vehicleURL, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${UseAuth("get")}`,
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (response.ok) {
          snackBarElement.current.displayToast("Vehicle Deleted");
          const updatedVehicles = vehicles.filter(
            (vehicle) => ![vehicle_models].includes(vehicle.model)
          );
          setVehicles(updatedVehicles);
        } else
          errorHandler(
            response,
            snackBarElement,
            "Failed to delete vehicle model."
          );
      })
      .catch((err) => {
        snackBarElement.current.displayToast(
          "Something is not right, try again",
          "error"
        );
        console.log(err);
      });
  };

  /**
   * Deletes charger row(s), sends backend the names of the charger(s) to be deleted
   * @param {String} charger_models string of model name to be deleted
   */
  const handleChargerDelete = (charger_models) => {
    const body = { model_list: [charger_models] };

    fetch(chargerURL, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${UseAuth("get")}`,
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (response.ok) {
          snackBarElement.current.displayToast("Charger Deleted");
          const updatedChargers = chargers.filter(
            (charger) => ![charger_models].includes(charger.model)
          );
          setChargers(updatedChargers);
        } else
          errorHandler(
            response,
            snackBarElement,
            "Failed to delete charger model."
          );
      })
      .catch((err) => {
        snackBarElement.current.displayToast(
          "Something is not right, try again",
          "error"
        );
        console.log(err);
      });
  };

  /**
   * Deletes emission row(s), sends backend the names of the emission(s) to be deleted
   * @param {Number} emission_id string of model name to be deleted
   */
  const handleEmissionDelete = (emission_id) => {
    const body = { emissions_id_list: [emission_id] };

    fetch(emissionsURL, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${UseAuth("get")}`,
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (response.ok) {
          snackBarElement.current.displayToast("Emission Deleted");

          const updatedEmissions = emissions.filter(
            (emission) => ![emission_id].includes(emission.id)
          );
          setEmissions(updatedEmissions);
        } else
          errorHandler(
            response,
            snackBarElement,
            "Failed to delete emmissions model"
          );
      })
      .catch((err) => {
        snackBarElement.current.displayToast(
          "Something is not right, try again",
          "error"
        );
        console.log(err);
      });
  };

  const MemoizedMasterTable = useMemo(() => {
    const data =
      tableDisplay == 1 ? vehicles : tableDisplay == 2 ? chargers : emissions;
    return (
      <MaterialTable
        title="Vehicle / Charger / Emissions Models"
        columns={
          tableDisplay == 1
            ? vehicleColumns
            : tableDisplay == 2
            ? chargerColumns
            : emissionColumns
        }
        data={data}
        components={{
          Toolbar: (props) => (
            <div>
              <MTableToolbar {...props} />
              <div style={{ padding: "0px 10px" }}>
                <Stack direction="row" spacing={1}>
                  <Chip
                    label="Vehicle Models"
                    variant={tableDisplay == 1 ? "filled" : "outlined"}
                    onClick={() => setTableDisplay(1)} //sets table display to "Vehicle Models"
                  />
                  <Chip
                    label="Charger Models"
                    variant={tableDisplay == 2 ? "filled" : "outlined"}
                    onClick={() => setTableDisplay(2)} //sets table display to "Charger models"
                  />
                  <Chip
                    label="Emission Models"
                    variant={tableDisplay == 3 ? "filled" : "outlined"}
                    onClick={() => setTableDisplay(3)} //sets table display to "Charger models"
                  />
                </Stack>
              </div>
            </div>
          ),
        }}
        icons={Icons()}
        localization={{
          body: {
            emptyDataSourceMessage: error ? (
              "Error Fetching Data/No Data Found"
            ) : data.length ? ( // if the data has been fetched, but the user has filtered out all entries
              <Container sx={{ ml: 0 }}>No Data Found</Container>
            ) : (
              // until the point that an error occurs or the data is retrieved, display a loading message in table
              <Container sx={{ ml: 0 }}>
                <CircularProgress />
                <br />
                Loading...
              </Container>
            ),
          },
          toolbar: { searchPlaceholder: "Filter", searchTooltip: "Filter" },
        }}
        actions={[
          {
            icon: EditIcon,
            tooltip: "Edit",
            onClick: (event, rowData) =>
              handleModelDialogOpen(tableDisplay, rowData),
            hidden: !accessRights.master_data.update,
          },
        ]}
        editable={{
          // onRowUpdate has been replaced with the above editIcon
          onRowDelete:
            (accessRights.master_data.delete || undefined) &&
            ((oldData) =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  tableDisplay == 1 //determines whether the user want to delete a charger or a vehicle
                    ? handleVehicleDelete(oldData.model)
                    : tableDisplay == 2
                    ? handleChargerDelete(oldData.model)
                    : handleEmissionDelete(oldData.id);
                  resolve();
                }, 1000);
              })),
        }}
      />
    );
  }, [tableDisplay, vehicles, chargers, emissions, accessRights.master_data]);

  //todo: consider adding the below check to other pages
  if (!accessRights.master_data.create) {
    //read_own_org_projects is the flag used to check if the access rights have finished fetching yet
    if (accessRights.projects.read_own_org_projects !== null) {
      //not a member of staff
      return <Navigate to="/notauthorized" />;
    } else {
      //still checking if user is a staff member or not
      return (
        <div className="centered">
          Authenticating...
          <br />
          <CircularProgress />
        </div>
      );
    }
  }

  return (
    <>
      <br /> <br />
      <Container fixed maxWidth="xl">
        <Paper sx={{ width: "100% ", overflow: "hidden" }} elevation={3}>
          {MemoizedMasterTable}
        </Paper>
      </Container>
      <br />
      <Container component="main" maxWidth="md">
        <Box
          sx={{
            marginTop: 2,
            marginBottom: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={4}>
              <Button
                fullWidth
                color="primary"
                variant="outlined"
                className="btn"
                onClick={() => setDialogState({ dialogView: 1 })}
                disabled={!accessRights.master_data.create}
              >
                Create New Vehicle Model
              </Button>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <Button
                fullWidth
                color="primary"
                variant="outlined"
                className="btn"
                onClick={() => setDialogState({ dialogView: 2 })}
                disabled={!accessRights.master_data.create}
              >
                Create New Charger Model
              </Button>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <Button
                fullWidth
                color="primary"
                variant="outlined"
                className="btn"
                onClick={() => setDialogState({ dialogView: 3 })}
                disabled={!accessRights.master_data.create}
              >
                Create New Emission Model
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Container>
      {/** Pop-up for creating/editing a charger Model */}
      <MasterDataDialogs
        state={dialogState}
        setState={setDialogState}
        allData={
          dialogState?.dialogView === 1
            ? vehicles
            : dialogState?.dialogView === 2
            ? chargers
            : dialogState?.dialogView === 3
            ? emissions
            : []
        }
        setAllData={
          dialogState?.dialogView === 1
            ? setVehicles
            : dialogState?.dialogView === 2
            ? setChargers
            : setEmissions
        }
        resetToggle={resetToggle}
        setResetToggle={setResetToggle}
      />
      {/** Pop-up for creating/editing an Emission Model */}
      <MasterDataEmissionsDialog
        state={dialogState}
        setState={setDialogState}
        allEmissions={emissions}
        setAllEmissions={setEmissions}
      />
    </>
  );
}
