import DeleteSharpIcon from "@mui/icons-material/DeleteSharp";
import LoadingButton from "@mui/lab/LoadingButton";
import { Skeleton } from "@mui/material";
import Alert from "@mui/material/Alert";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import axios from "axios";
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { SnackBarContext } from "../../../contexts/snackBarContext";
import {
  resourceURL,
  schoolBusDownloadURL,
  schoolBusURL,
  simulationURL,
} from "../../../static/constants/backendRoutes";
import stepInfo from "../../../static/constants/stepInfo";
import UseAuth from "../../auth/useAuth";
import { AssessmentAnalysisStepper } from "../../secondary/steppers";
import TimedCircularProgress from "../../secondary/timedCircularProgress";
import {
  errorHandler,
  getLocalData,
  getMasterData,
  partialClearLocalData,
  storeLocalData,
} from "../../utils";
import { NextPageButton } from "../commonComponents";
import SimulationSubtitle from "../dialogs/simulationSubtitle";

const STEP_NUMBER = 0;

function SchoolBusImport() {
  const [inputFile, setInputFile] = useState({
    school_bus_inventory: "",
  });
  const [isInputFilePicked, setIsInputFile] = useState({
    school_bus_inventory: false,
  });

  const [currentProject, setCurrentProject] = useState({});
  const [buttonLoading, setButtonLoading] = useState(false); //for displaying loading effect

  const [schoolbusSizes, setSchoolbusSizes] = useState(undefined);

  const navigate = useNavigate();

  const { snackBarElement } = useContext(SnackBarContext);

  function Item(props) {
    const { sx, ...other } = props;
    return (
      <Box
        sx={{
          bgcolor: (theme) =>
            theme.palette.mode === "dark" ? "#101010" : "#fff",
          color: (theme) =>
            theme.palette.mode === "dark" ? "grey.300" : "grey.800",
          border: "1px solid",
          borderColor: (theme) =>
            theme.palette.mode === "dark" ? "grey.800" : "grey.300",
          p: 1,
          borderRadius: 2,
          fontSize: "0.875rem",
          fontWeight: "600",
          ...sx,
        }}
        {...other}
      />
    );
  }

  Item.propTypes = {
    /**
     * The system prop that allows defining system overrides as well as additional CSS styles.
     */
    sx: PropTypes.oneOfType([
      PropTypes.arrayOf(
        PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
      ),
      PropTypes.func,
      PropTypes.object,
    ]),
  };

  useEffect(() => {
    /** retrieves project data from indexDB, and then
     * fetches depot and vehicle data related to that project
     */
    async function fetchData() {
      //fetches project data from the localDb, and stores it in currentProjectState
      const { data: currentProject } = await getLocalData("project", "data");

      const depot_id = await getLocalData("simulation", "data").then(
        ({ data }) => data?.depot_id
      );
      if (!currentProject || !depot_id) {
        //if no current project stored in frontend
        console.log("no project in localDb");
        snackBarElement?.current?.displayToast(
          "Unable to Find Current Simulation's Project, Please Return to Fleet Operation Input and Try Again",
          "error"
        );
        return;
      }

      setCurrentProject({ ...currentProject, depot_id });

      const headers = {
        Authorization: `Token ${UseAuth("get")}`,
        "Content-Type": "application/json",
      };

      function initSchoolbusSizes(vehicleResource, allVehiclesMaster) {
        const vehiclesInDepot = vehicleResource
          .filter((veh) => veh.depot_id == depot_id)
          .map((veh) => veh.resource_id);

        // filters out the non-schoolbus vehicles
        const allSchoolbusMaster = allVehiclesMaster.filter(
          ({ type }) => type == 4
        );

        setSchoolbusSizes({
          project: allSchoolbusMaster.map(({ size }) => size),
          depot: allSchoolbusMaster
            .filter(({ model }) => vehiclesInDepot.includes(model))
            .map(({ size }) => size),
        });
      }

      //fetches all vehicles in the selected project
      fetch(`${resourceURL}?project_id=${currentProject.id}&type=2`, {
        method: "GET",
        headers: headers,
      }).then((res) => {
        if (res.ok)
          res.json().then(({ data: vehicleResource }) =>
            //gets all vehicles in masterdata, and initializes lists. This is entirely done just to populate the type list in a snackbar
            getMasterData(snackBarElement, {
              models: vehicleResource.map((veh) => veh.resource_id),
              onSuccess: (masterVehicles) =>
                initSchoolbusSizes(vehicleResource, masterVehicles),
            })
          );
        else errorHandler(res, snackBarElement);
      });
    }

    fetchData();
  }, []);

  /** downloads an example excel file */
  const handleDownload = () => {
    let headers = {
      Authorization: `Token ${UseAuth("get")}`,
    };
    axios({
      url: schoolBusDownloadURL,
      method: "GET",
      headers: headers,
      responseType: "blob",
    }).then((response) => {
      const downloadURL = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = downloadURL;
      link.setAttribute("download", `school_bus_template.xlsx`);
      document.body.appendChild(link);
      link.click();
    });
  };

  /** determines if the school bus file has been selected yet or not */
  const disabledButton = !Object.values(isInputFilePicked).every(Boolean);

  const removeFile = (key) => {
    let copyInputFile = { ...inputFile };
    copyInputFile[key] = "";
    setInputFile(copyInputFile);

    let copyIsInputFilePicked = { ...isInputFilePicked };
    copyIsInputFilePicked[key] = false;
    setIsInputFile(copyIsInputFilePicked);
  };

  /**
   * Stores the uploaded files in a useState
   * @param {*} event contains the file uploaded by the user
   * @param {String} key stops/stop_times/trips/routes (specifies the type input file)
   */
  const InputFileChangeHandler = (event, key) => {
    setInputFile((upload) => {
      upload[key] = event.target.files[0];
      return { ...upload };
    });
    setIsInputFile((upload) => {
      upload[key] = true;
      return { ...upload };
    });
  };

  /**
   * packs everything up and sends it sends it to the schoolbus api
   * then parses the block data out of the response based on vehicle efficiency
   * and model, then stores everything into indexDB (localDb)
   * @param {*} event
   */
  const handleSubmit = async (event) => {
    const formData = new FormData();
    formData.append("school_bus_file", inputFile["school_bus_inventory"]);
    formData.append("project_id", currentProject.id); //note: remove this project_id at some point, once GOL-445 is merged on backend
    formData.append("depot_id", currentProject.depot_id);

    setButtonLoading(true);

    const { data: sim } = await getLocalData("simulation", "data");

    const headers = {
      Authorization: `Token ${UseAuth("get")}`,
      Accept: `application/json; version=${sim.analysis_type_steps.create_simulation.import_school_bus}`,
    };

    fetch(schoolBusURL, {
      method: "POST",
      headers: headers,
      body: formData,
    })
      .then((response) => {
        delete headers["Accept"];
        if (response.ok) {
          return response.json().then(({ blocks }) => {
            storeLocalData("blocks", { data: blocks });

            //Save the data on the backend Body
            const backendBody = {
              id: sim.id,
              current_page: stepInfo[STEP_NUMBER + 1].route,
              steps: {
                blocks: blocks, //the blocks to save on the backend

                //clear out the future pages' backend data
                routeEnergy: {},
                battery: {},
                fleetSizing: {},
                evAssessment: {},
                financial: {},
                tco: {},
              },
              completed: false,
            };

            partialClearLocalData([
              "routeEnergy",
              "battery",
              "fleetSizing",
              "evAssessment",
              "financial",
              "tco",
            ]);

            let simHeaders = {
              Authorization: `Token ${UseAuth("get")}`,
              "Content-Type": "application/json",
            };
            fetch(simulationURL, {
              method: "PATCH",
              headers: simHeaders,
              body: JSON.stringify(backendBody),
            })
              .then((response) => {
                if (response.ok) {
                  snackBarElement?.current?.displayToast(
                    "Excel Parsing Complete",
                    "success"
                  );
                  navigate(stepInfo[STEP_NUMBER + 1].route);
                } else {
                  errorHandler(
                    response,
                    snackBarElement,
                    "Error Sending Data to Backend"
                  );
                  setButtonLoading(false);
                }
              })
              .catch((e) => {
                snackBarElement?.current?.displayToast(
                  "Error Sending Data to Backend",
                  "error"
                );
                setButtonLoading(false);
                console.log("error", e);
              });
          });
          //original POST errors
        } else {
          errorHandler(
            response,
            snackBarElement,
            "Failed to run fleet operation"
          );
          setButtonLoading(false);
        }
      })
      .catch((err) => {
        setButtonLoading(false);
        snackBarElement?.current?.displayToast(String(err), "error");
      });
  };

  return (
    <div>
      <br />
      <br />
      <AssessmentAnalysisStepper stepNum={STEP_NUMBER} />
      <br />
      {Object.keys(currentProject).length > 0 ? (
        <div>
          <br />
          <Container
            fixed
            sx={{
              alignItems: "center",
              display: "flex",
            }}
          >
            <Typography
              variant="h5"
              gutterBottom
              component="div"
              align="left"
              className="page-title"
            >
              School&nbsp;Bus&nbsp;Inventory&nbsp;Import
            </Typography>
            <SimulationSubtitle />
          </Container>
          <br />
          {schoolbusSizes ? (
            <Container maxWidth="sm">
              <Alert severity="info" sx={{ textAlign: "left" }}>
                {/* todo: left-align */}
                School Bus sizes currently in Project (all depots):{" "}
                {schoolbusSizes.project
                  .map((size) => ` Type ${size}`)
                  .join(",")}
                <br />
                School Bus sizes currently in Selected Depot:{" "}
                {schoolbusSizes.depot.map((size) => ` Type ${size}`).join(",")}
              </Alert>
            </Container>
          ) : (
            <Container maxWidth="sm">
              <Skeleton animation="wave" width="100%">
                <Alert severity="info">
                  <br />
                  <br />
                </Alert>
              </Skeleton>
            </Container>
          )}
          <br />
          <Container maxWidth="sm">
            <Alert severity="info" sx={{ textAlign: "left" }}>
              {disabledButton ? (
                <>
                  Please upload school bus schedule excel file
                  <br />
                  (Only include School Bus sizes in Project or Selected Depot)
                </>
              ) : (
                `File uploaded, please run block definition`
              )}
            </Alert>
          </Container>
          <Container
            fixed
            sx={{
              alignItems: "center",
              flexDirection: "column",
              justifyContent: "center",
              paddingTop: "5%",
              display: "inline-block",
            }}
          >
            <Box sx={{ display: "grid", gridAutoColumns: "1fr", gap: 1 }}>
              {Object.keys(inputFile).map((key, index) => (
                <Item
                  sx={{
                    gridRow: `${index === 0 ? "2" : "1"}`,
                    gridColumn: `${index === 0 ? "span 4" : ""}`,
                  }}
                  key={key}
                >
                  <LoadingButton
                    variant="outlined"
                    className="btn"
                    component="label"
                    loading={!schoolbusSizes?.depot}
                  >
                    <input
                      id={key + "Button"}
                      type="file"
                      accept=".xlsx, .xls"
                      name={key}
                      onChange={(event) => InputFileChangeHandler(event, key)}
                      required
                      className="excel-import-input"
                    />
                    Upload {key.replaceAll("_", " ")} File
                  </LoadingButton>
                  <br />
                  <Button
                    onClick={handleDownload}
                    variant="outlined"
                    className="btn"
                    component="div"
                    style={{ marginTop: "10px" }}
                  >
                    DOWNLOAD TEMPLATE FILE
                  </Button>

                  {isInputFilePicked[key] && (
                    <div>
                      <p>
                        <b>Filename:</b> {inputFile[key].name}
                        <span
                          style={{ minHeight: "200px" }}
                          onClick={() => removeFile(key)}
                        >
                          <DeleteSharpIcon
                            style={{
                              verticalAlign: "middle",
                              marginLeft: "2.5px",
                              marginBottom: "3px",
                              cursor: "pointer",
                            }}
                            fontSize="small"
                          />
                        </span>
                        <br />
                      </p>
                    </div>
                  )}
                </Item>
              ))}
            </Box>
            <br />
            <br />
            <br />
            <NextPageButton
              sx={{ width: "95%" }}
              disabled={disabledButton}
              onClick={handleSubmit}
              loading={buttonLoading}
            >
              Process Data
            </NextPageButton>
          </Container>
        </div>
      ) : (
        <div>
          <TimedCircularProgress
            text={
              <Typography variant="h3" gutterBottom component="div">
                No <Link to={stepInfo[STEP_NUMBER].route}>Project</Link>{" "}
                Selected. Select one and come back.
              </Typography>
            }
          />
        </div>
      )}

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={buttonLoading}
      >
        <Container alignitems="center" justify="center" aligncontent="center">
          <Container align="center">
            <CircularProgress color="inherit" />
          </Container>
          <br />
          <Container align="center">
            <Typography variant="h5">
              <b>Sending data over to {stepInfo[STEP_NUMBER + 1].label}</b>
            </Typography>
          </Container>
        </Container>
      </Backdrop>
    </div>
  );
}

export default SchoolBusImport;
