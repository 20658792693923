import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { DataContext } from "../../contexts/dataContext";
import stepInfo, {
  assessmentStepZero,
  comparisonStepInfo,
} from "../../static/constants/stepInfo";

export default function SideDrawer(props) {
  const { pageAccessMemo, accessRights, analysisStepsViewMemo } =
    useContext(DataContext);
  const { isAuth, setSideDrawer, sideDrawer: state } = props;
  const sideBarWidth = 250;
  const [projectDropdownOpen, setProjectDropdownOpen] = useState(false);
  const [analysisDropdownOpen, setAnalysisDropdownOpen] = useState(false);
  const [adminDropdownOpen, setAdminDropdownOpen] = useState(false);
  const [disableAnalysisDropdown, setDisableAnalysisDropdown] = useState(true);

  //if user has no projects created, disable all buttons
  const disableAllButtons = pageAccessMemo.pageAccess < 1;

  const sideBarLinks = {
    "All Depots": "/depot",
    "All Resources": "/resource-inventory",
  };

  //todo: add the stepZero to assessment-type analysis stepInfo
  const stepInfoSliced =
    analysisStepsViewMemo?.state?.analysis_type == 1
      ? [
          assessmentStepZero[analysisStepsViewMemo?.state?.input_method || 0], // prepends the variable "input method step" for "assessment"-type analyses
        ].concat(stepInfo.slice(1))
      : comparisonStepInfo;

  let bottomLinks = {};

  if (accessRights.admin.read_partner) bottomLinks.Partner = "/partner";
  if (accessRights.admin.read_customer) bottomLinks.Customer = "/customer";
  if (accessRights.admin.read_user) bottomLinks.User = "/user";
  if (accessRights.admin.list_licenses) bottomLinks.Licenses = "/licenses";
  if (accessRights.projects.read_all_projects)
    //note: any-level admin flag
    bottomLinks["Projects"] = "/admin-projects";
  if (accessRights.master_data.create)
    //note: site-level admin flag
    bottomLinks["Analyses"] = "/admin-analysis";

  const location = useLocation(); ///gets location, for determining the current page to highlight in navbar

  useEffect(() => {
    async function fetchData() {
      if (isAuth && state) {
        document.getElementById("App").style.marginLeft = `${sideBarWidth}px`;
      } else {
        document.getElementById("App").style.marginLeft = `0px`;
      }
    }
    fetchData();
  }, [isAuth, state]);

  useEffect(() => {
    function updateSideDrawer() {
      if (analysisStepsViewMemo?.state?.analysis_type == 0) {
        setAnalysisDropdownOpen(false);
        setDisableAnalysisDropdown(true);
      } else {
        setDisableAnalysisDropdown(false);
      }
    }

    updateSideDrawer();
  }, [analysisStepsViewMemo]);

  return (
    <Drawer
      id="side-drawer"
      elevation={-1}
      sx={{
        "& .MuiDrawer-paper": {
          boxSizing: "border-box",
          minHeight: "100%",
          bgcolor: "rgba(250,250,252)",
          position: "fixed",
          zIndex: 1,
        },
      }}
      variant="persistent"
      anchor="left"
      open={isAuth && state}
    >
      <Box sx={{ width: sideBarWidth, marginTop: "10%" }} role="presentation">
        <ListItemButton
          title="Collapse"
          key="close"
          onClick={() => setSideDrawer(false)}
          sx={{ display: "flex", justifyContent: "flex-end" }}
          tabIndex={-1} //disables navigating  to this using keyboard by pressing tab
        >
          <ChevronLeft />
        </ListItemButton>

        <Divider />
        <List>
          <div style={{ alignItems: "center", display: "flex" }}>
            <ListItemButton
              key={"project"}
              component={Link}
              to={"project"}
              selected={`/project` == location.pathname}
              tabIndex={-1} //disables navigating  to this using keyboard by pressing tab
            >
              <ListItemText primary="Projects" />
            </ListItemButton>
            <Divider
              orientation="vertical"
              variant="middle"
              flexItem
              sx={{ mr: "5px" }}
            />
            <IconButton
              title="More Details"
              onClick={() => setProjectDropdownOpen(!projectDropdownOpen)}
              sx={{ mr: "10px" }}
            >
              {projectDropdownOpen ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          </div>

          {/* Project dropdown */}
          <Collapse in={projectDropdownOpen}>
            <List component="div" disablePadding>
              {Object.entries(sideBarLinks).map(([label, route]) => (
                <ListItemButton
                  sx={{ pl: 4 }}
                  key={label}
                  component={Link}
                  to={route}
                  selected={route == location.pathname}
                  disabled={disableAllButtons}
                  tabIndex={-1} //disables navigating  to this using keyboard by pressing tab
                >
                  <ListItemText primary={label} />
                </ListItemButton>
              ))}
            </List>
          </Collapse>

          <Divider />
          <div style={{ alignItems: "center", display: "flex" }}>
            <ListItemButton
              key={"simulations"}
              component={Link}
              to={"simulations"}
              selected={`/simulations` == location.pathname}
              disabled={disableAllButtons}
              tabIndex={-1} //disables navigating  to this using keyboard by pressing tab
            >
              <ListItemText primary="Analyses" />
            </ListItemButton>
            <Divider
              orientation="vertical"
              variant="middle"
              flexItem
              sx={{ mr: "5px" }}
            />
            <IconButton
              title="Analysis Steps"
              disabled={disableAnalysisDropdown}
              onClick={() => setAnalysisDropdownOpen(!analysisDropdownOpen)}
              sx={{ mr: "10px" }}
            >
              {analysisDropdownOpen ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          </div>

          {/* Analysis dropdown */}
          <Collapse in={analysisDropdownOpen}>
            <List component="div" disablePadding>
              {stepInfoSliced.map(({ route, label }) => (
                <ListItemButton
                  sx={{ pl: 4 }}
                  key={label}
                  component={Link}
                  to={route}
                  selected={route == location.pathname}
                  disabled={disableAllButtons}
                  tabIndex={-1} //disables navigating  to this using keyboard by pressing tab
                >
                  <ListItemText primary={label} />
                </ListItemButton>
              ))}
            </List>
          </Collapse>

          {/* Admin dropdown */}
          {Object.values(accessRights.admin).every(
            (accessRight) => !accessRight //all access rights are false or undefined
          ) ? undefined : (
            <>
              <Divider />
              <ListItemButton
                onClick={() => setAdminDropdownOpen(!adminDropdownOpen)}
              >
                <ListItemText primary="Admin" />
                <ListItemIcon sx={{ minWidth: "26px" }}>
                  {adminDropdownOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItemIcon>
              </ListItemButton>
              <Collapse in={adminDropdownOpen}>
                <List sx={{ py: 0 }}>
                  {Object.entries(bottomLinks).map(([label, route]) => (
                    <ListItemButton
                      key={label}
                      component={Link}
                      to={route}
                      sx={{ pl: 4 }}
                      disabled={"Settings" == label}
                      selected={route == location.pathname}
                      tabIndex={-1} //disables navigating  to this using keyboard by pressing tab
                    >
                      <ListItemText primary={label} />
                    </ListItemButton>
                  ))}
                </List>
              </Collapse>
            </>
          )}

          {/* Master data */}
          {accessRights?.master_data?.create && (
            <>
              <Divider />
              <ListItemButton
                key="Master Data"
                component={Link}
                to="masterdata"
                selected={`/masterdata` == location.pathname}
                tabIndex={-1} //disables navigating  to this using keyboard by pressing tab
              >
                <ListItemText primary="Master Data" />
              </ListItemButton>
            </>
          )}
        </List>
      </Box>
    </Drawer>
  );
}
