import {
  Button,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  MenuItem,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { DataContext } from "../../contexts/dataContext";
import { SnackBarContext } from "../../contexts/snackBarContext";
import { fetchCountries, fetchStates, stringCapitalize } from "../utils";

/**
 * creates a colored circle filled with a given color
 * @param {import("react").DetailedHTMLProps<import("react").HTMLAttributes<HTMLSpanElement>, HTMLSpanElement> && Object} param0
 * @param {string} [param0.color="#3A83F7"] the css color of the circle's filling
 * @param {string} [param0.label=""] the label to display on mouse over
 */
export function ColoredCircle({ color = "#3A83F7", label = "", ...props }) {
  return (
    <Tooltip title={label}>
      <span
        {...props}
        style={{
          height: "1.5em",
          width: "1.5em",
          borderColor: "black",
          borderWidth: "1px",
          borderStyle: "solid",
          borderRadius: "50%",
          display: "inline-block",
          boxShadow: "0 0 2px 2px #0008", //adds in a shadow effect
          backgroundColor: color,
          ...props?.style,
        }}
      />
    </Tooltip>
  );
}

/**
 * React component that handles the country/state/city selection
 * sends request to backend to populate the next dropdown each selection
 */
export function LocationDropDowns(props) {
  const { profile } = props;
  const extraProps = props?.props;
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);

  const { snackBarElement } = useContext(SnackBarContext);

  /** Fetches all countries when dialogbox is opened */
  useEffect(() => {
    //todo: consider if this would be better fetched on page visits instead of dialog box opening
    async function fetchData() {
      const countries = await fetchCountries(snackBarElement);
      setCountries(countries);

      if (profile?.country_code) {
        const states = await fetchStates(profile.country_code, snackBarElement);
        setStates(states);
      }
    }

    if (!countries.length) fetchData();
  }, []);

  /**
   * fires when a country is selected from the country dropdown,
   * and retrieves the states of that country for the state dropdown
   * @param {String} country_code
   */
  const handleCountrySelect = async (country_code) => {
    const states = await fetchStates(country_code, snackBarElement);
    setStates(states);
  };

  return (
    <>
      <Grid item xs={6} sm={3}>
        <TextField
          id="country_code"
          required
          name="country_code"
          fullWidth
          label="Country"
          select //a Select-type textfield
          SelectProps={{
            //set an upper limit to the height of the dropdown
            MenuProps: { PaperProps: { sx: { maxHeight: "60%" } } },
          }}
          defaultValue={profile.country_code}
          {...extraProps}
        >
          {countries.map((country) => (
            <MenuItem
              value={country.iso2}
              key={country.iso2}
              onClick={() => handleCountrySelect(country.iso2)}
            >
              {country.emoji} {country.name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={6} sm={3}>
        <TextField
          id="state_code"
          required
          name="state_code"
          fullWidth
          label="State"
          select //a Select-type textfield
          SelectProps={{
            MenuProps: { PaperProps: { sx: { maxHeight: "60%" } } },
          }}
          defaultValue={profile.state_code}
          {...extraProps}
        >
          {states.map((state) => (
            <MenuItem value={state.state_code} key={state.state_code}>
              {state.name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
    </>
  );
}

/**
 * A standard dialog box's form contents for the admin pages
 * @param {Object} props
 * @param {JSON} props.data default data to be displayed/auto-filled in form
 * @param {String} props.pageview String of which page the user is coming from (for adding/removing certain fields)
 * @returns {React Element}
 */
export default function DefaultDialog(props) {
  const profile = props.data;
  const pageview = props.pageview;
  const parentNames = props.parentNames;
  const [orgLogo, setOrgLogo] = useState();
  const [isBranding, toggleBranding] = useState(props.enable_brand);
  const [subdomain, setSubdomain] = useState(profile.subdomain || "");

  const { accessRights } = useContext(DataContext);

  /**
   * @param {React.ChangeEvent<HTMLInputElement>} event
   */
  const handleLogoUpload = (event) => {
    const file = event.target.files[0];
    if (file?.size >= 20971520) {
      alert("File size is too large! Please select a smaller image.");
      return undefined;
    }
    setOrgLogo(file);
  };

  return (
    <>
      <DialogTitle display="flex" justifyContent="space-between">
        New {stringCapitalize(pageview)}
        <span>
          <Button variant="outlined" component="label" required={isBranding}>
            Upload Logo ({isBranding ? "required" : "optional"})
            <input
              type="file"
              hidden
              accept="image/*"
              id="logo"
              name="logo"
              onChange={handleLogoUpload}
            />
          </Button>
          {orgLogo && (
            <>
              {" "}
              <b>Logo:</b> {orgLogo.name}
            </>
          )}
        </span>
        <FormControlLabel
          control={
            <Switch
              name="enable_brand"
              color="secondary"
              value={isBranding}
              onChange={() => toggleBranding(!isBranding)}
            />
          }
          label="Add Brand"
          labelPlacement="start"
        />
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={4}>
          {pageview == "customer" && accessRights.admin.read_partner && (
            <Grid item xs={12} sm={12} className="grid-left">
              <TextField
                required
                id="parent_id"
                name="parent_id"
                fullWidth
                label="Parent Name"
                select
                SelectProps={{
                  //set an upper limit to the height of the dropdown
                  MenuProps: { PaperProps: { sx: { maxHeight: "60%" } } },
                }}
                defaultValue={profile.parent_id}
              >
                {parentNames.map((partner) => (
                  <MenuItem value={partner.id} key={partner.id}>
                    {partner.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          )}
          <Grid item xs={12} sm={6} className="grid-left">
            <TextField
              id="name"
              required
              name="name"
              fullWidth
              label={pageview == "partner" ? "Partner Name" : "Customer Name"}
              type="text"
              defaultValue={profile.name}
            />
          </Grid>
          <Grid item xs={12} sm={6} className="grid-right">
            <TextField
              id="website"
              required
              name="website"
              fullWidth
              label="Website"
              type="url"
              defaultValue={profile.website}
            />
          </Grid>
          <Grid item xs={12} sm={6} className="grid-left">
            <TextField
              id="description"
              required
              name="description"
              fullWidth
              label="Description"
              type="text"
              multiline
              maxRows={4}
              defaultValue={profile.description}
            />
          </Grid>
          <Grid item xs={12} sm={6} className="grid-right">
            <TextField
              id="phone_number"
              required
              name="phone_number"
              fullWidth
              label="Phone Number"
              type="tel" //tel for telephone
              defaultValue={profile.phone_number}
            />
          </Grid>
          {pageview == "customer" && ( //customer-specific dropdown
            <>
              <Grid item xs={12} sm={6} className="grid-left">
                <TextField
                  id="end_user_type"
                  required
                  name="end_user_type"
                  fullWidth
                  label="Type"
                  select //a Select-type textfield
                  SelectProps={{
                    MenuProps: { PaperProps: { sx: { maxHeight: "60%" } } },
                  }}
                  defaultValue={profile.end_user_type}
                >
                  <MenuItem value="Consultant">Consultant</MenuItem>
                  <MenuItem value="Fleet Operator">Fleet Operator</MenuItem>
                  <MenuItem value="Utility">Utility</MenuItem>
                  <MenuItem value="Other">Other</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12} sm={6} className="grid-right" />
            </>
          )}
          <LocationDropDowns profile={{ ...profile }} />
          <Grid item xs={6} sm={3}>
            <TextField
              id="city"
              required
              name="city"
              fullWidth
              label="City"
              type="text"
              defaultValue={profile.city}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <TextField
              id="zipcode"
              required
              name="zipcode"
              autoComplete="postal-code"
              fullWidth
              label="Zipcode"
              defaultValue={profile.zipcode}
            />
          </Grid>
        </Grid>
        <br />
        <br />
        {isBranding ? (
          <>
            <Typography
              variant="h5"
              gutterBottom
              align="left"
              className="page-title"
            >
              Brand Subdomain
            </Typography>
            <TextField
              required
              name="subdomain"
              label="Subdomain"
              sx={{ minWidth: "30%" }}
              value={subdomain}
              onChange={(e) =>
                setSubdomain(
                  e.target.value
                    .toLocaleLowerCase()
                    .replaceAll(" ", "_")
                    .replaceAll(".", "-")
                )
              }
              helperText={`${subdomain || "*"}.planner.ev-opt.com`}
              multiline //multiline, added just for fun really. no one's going to have a subdomain that long
            />
            <br /> <br /> <br />
          </>
        ) : (
          <>
            <Typography
              variant="h5"
              gutterBottom
              align="left"
              className="page-title"
            >
              Admin User
            </Typography>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={4}>
                <TextField
                  id="admin_first_name"
                  required
                  name="admin_first_name"
                  fullWidth
                  label="First Name"
                  type="text"
                  defaultValue={profile.admin_first_name}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  id="admin_last_name"
                  required
                  name="admin_last_name"
                  fullWidth
                  label="Last Name"
                  type="text"
                  defaultValue={profile.admin_last_name}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  id="admin_email"
                  required
                  name="admin_email"
                  fullWidth
                  label="Email"
                  type="email"
                  defaultValue={profile.admin_email}
                />
              </Grid>
              {/* couple of blank lines to separate profile from buttons */}
              <Grid item xs={12} />
              <Grid item xs={12} />
            </Grid>
          </>
        )}
      </DialogContent>
    </>
  );
}
