import { DialogContent, Grid, Paper, Radio, Typography } from "@mui/material";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { useMemo, useState } from "react";
import { Bar } from "react-chartjs-2";
import materialReactTableOptions from "../../../static/constants/defaultMaterialReactTableOptions";
import { unitSmallMap } from "../../../static/constants/systems_of_measurement";
import TYPE_STRINGS from "../../../static/constants/TYPE_STRINGS";
import { getUnits, unitFeet } from "../../secondary/unitConversions";
import { parseFromValuesOrFunc, roundNumber, unitWrapper } from "../../utils";
import FleetSizingGraph from "../graphs/fleetSizingGraph";

/**
 * @typedef {Object} CustomGridItemTypes
 * @property {import("react").ReactNode} children
 * @property {Number} [size=4] the grid item size
 * @property {import("@mui/material").PaperProps} paperProps
 */

/**
 * a standard grid item component used across this file
 * @param {import("@mui/material").GridProps & CustomGridItemTypes} param0
 */
function CustomGridItem({ children, size = 4, paperProps, ...props }) {
  return (
    <Grid item xs={12} sm={size} {...props}>
      <Paper
        sx={{
          alignContent: "center",
          textAlign: "center",
          height: "100%",
          ...paperProps?.sx,
        }}
        {...paperProps}
      >
        {children}
      </Paper>
    </Grid>
  );
}

/**
 * @typedef {Object} fleet
 * @property {{size: number, battery_capacity: number, num_blocks: number, num_feasible_blocks: number, model: string, veh_type: string, ice_fleet_size: number, ev_fleet_size: number, replacement_ratio: Number}} model_breakdown
 * @property {number} total_ev_fleet_size
 * @property {number} total_ice_fleet_size
 * @property {number} total_replacement_ratio
 * @property {{bev_fleet_size: number, ice_fleet_size: number, num_blocks: number, num_feasible_blocks: Number, replacement_ratio: Number, size: string, veh_type: string}[]} type_size_breakdown
 */

/**
 * Dialog contents that allow user to select charger rating
 * @param {Object} param0
 * @param {{fleet: fleet, charger_model: {model:string}, block_fleet_growth_diff: {time: number, block: string}[], num_chargers: number }[]} param0.data
 * @param {Number} initialSelection
 */
export default function FleetSizingChargerSelection({
  data,
  initialSelection,
}) {
  const [rowSelection, setRowSelection] = useState({
    [initialSelection]: true,
  });
  const selectedRow = useMemo(
    () => +Object.keys(rowSelection)[0],
    [rowSelection]
  );
  const selectedVehicle = data[selectedRow]?.fleet?.model_breakdown;

  const units = getUnits();

  // Number of vehicles bar chart code
  /** @type {import("chart.js").ChartData} */
  const barChartData = {
    labels: ["ICE Vehicles", "BEV Vehicles"],
    datasets: [
      {
        label: "Number of Vehicles Needed",
        data: [selectedVehicle.ice_fleet_size, selectedVehicle.ev_fleet_size],
      },
    ],
  };

  /** @type {import("chart.js").ChartOptions} */
  const barChartOptions = {
    indexAxis: "y", //flips the bar chart to be horizontal
    maintainAspectRatio: false, //allows for variable height
    scales: {
      x: {
        suggestedMax:
          Math.max(
            selectedVehicle.ice_fleet_size,
            selectedVehicle.ev_fleet_size
          ) + 1,
      },
    },
  };

  // charger rating selection table code
  const columns = useMemo(
    /**
     * @returns {import("material-react-table").MRT_ColumnDef<never> []}
     */ () => [
      {
        header: "Rating",
        accessorKey: "charger_model.rating",
        Cell: ({ cell }) => (
          <>
            {cell.getValue()} {unitWrapper("kW")}
          </>
        ),
      },
      {
        header: "Replacement Ratio",
        id: "replacement_ratio",
        accessorFn: (row) =>
          roundNumber(row.fleet.model_breakdown.replacement_ratio),
        Cell: ({ cell }) => (
          <>
            <Typography variant="h5">{cell.getValue()}</Typography>
            BEV per ICE Vehicle
          </>
        ),
        muiTableBodyCellProps: { sx: { display: "table-cell" } },
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    ...materialReactTableOptions(),
    data: data,
    columns,
    initialState: {
      ...materialReactTableOptions().initialState,
      density: "comfortable",
    },
    state: {
      ...materialReactTableOptions().state,
      rowSelection,
    },
    //miscellaneous options
    enablePagination: false,
    enableBottomToolbar: false,
    enableTopToolbar: false,
    enableHiding: false,
    enableSorting: false, //disables column sorting
    enableColumnActions: false, //disables column options dropdowns
    enableTableHead: false, // hides the table header
    enableRowVirtualization: true, //makes the rows scrollable
    muiTableContainerProps: { sx: { maxHeight: 450 } },

    //column styling
    muiTableBodyCellProps: { align: "center" },

    //localization changes (custom text)
    localization: {
      select: "", //removes the header for the radio column
      toggleSelectRow: "Select Charger Rating",
    },

    //row selection options
    enableRowSelection: true,
    enableMultiRowSelection: false, //radio mode
    positionToolbarAlertBanner: "none", //hides the "n of m rows(s) selected" banner
    onRowSelectionChange: setRowSelection,
    muiTableBodyRowProps: (params) => ({
      ...parseFromValuesOrFunc(
        materialReactTableOptions().muiTableBodyRowProps,
        params
      ),
      onClick: params.row.getIsSelected()
        ? undefined
        : params.row.getToggleSelectedHandler(), // allows user to click anywhere on row to select (and prevents user from deselecting rows)
      sx: (theme) => ({
        ...parseFromValuesOrFunc(params?.sx, theme),
        cursor: "pointer",
      }),
    }),
  });

  return (
    <DialogContent>
      <Grid container alignItems="stretch" spacing={2}>
        <CustomGridItem size={4}>
          <Typography variant="h4">
            {TYPE_STRINGS.VEHICLE_TYPE[selectedVehicle.veh_type]} -&nbsp;
            {selectedVehicle.veh_type == 1 ? (
              <>
                {unitFeet(selectedVehicle.size)}
                {unitWrapper(unitSmallMap[units])}
              </>
            ) : selectedVehicle.veh_type == 4 ? ( //if selected vehicle type is not transit, hide the ft
              `Type ${selectedVehicle.size}`
            ) : (
              `Class ${selectedVehicle.size}`
            )}
            <br />
            {selectedVehicle.battery_capacity}
            {unitWrapper("kWh")} Battery
          </Typography>
        </CustomGridItem>
        <CustomGridItem size={8}>
          <Bar options={barChartOptions} data={barChartData} />
        </CustomGridItem>
        <Grid item xs={12} sm={4}>
          {/* used to pass the selected row up on form submission */}
          <input
            name="selectedRow"
            value={selectedRow}
            hidden={true}
            readOnly
          />
          <MaterialReactTable table={table} />
        </Grid>
        <CustomGridItem size={8}>
          <FleetSizingGraph
            data={[data[selectedRow]]}
            model={`${selectedVehicle.size}_${selectedVehicle.veh_type}`}
            chartOptions={{ animation: false }}
          />
        </CustomGridItem>
      </Grid>
    </DialogContent>
  );
}
