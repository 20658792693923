import {
  ReactComponentElement,
  createContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import UseAuth from "../components/auth/useAuth";
import { getLocalData, getSubdomain } from "../components/utils";
import { accessRightsURL, logoURL } from "../static/constants/backendRoutes";
import defaultAccessRights from "../static/constants/defaultAccessRights";
import img from "../static/images/evopt.png";

export const DataContext = createContext({
  pageAccessMemo: { pageAccess: 1, setPageAccess: () => null },
  analysisStepsViewMemo: {
    state: { analysis_type: 0, input_method: 0 },
    setState: () => null,
  },
  organizationMemo: {
    selectedOrg: "",
    setSelectedOrg: () => null,
    lookup: {},
    setLookup: () => null,
  },
  /** @type {import("../static/constants/defaultAccessRights.json")}*/
  accessRights: defaultAccessRights,
  logo: "",
  setLogo: () => null,
});

/**
 * @param {object} props
 * @param {ReactComponentElement} props.children
 */
const DataContextProvider = ({ children }) => {
  /**a flag to determine if all links should be disabled (should only be 0 if,
   * when visiting the projects page, there are no projects found. This is to prevent
   * users from going anywhere else on the site until they have created projects)
   * NOTE: used a Number instead of a Boolean, in case we want similar functionality
   *    for other things, specifically depots/resources in the future */
  const [pageAccess, setPageAccess] = useState(1);

  const pageAccessMemo = useMemo(
    () => ({ pageAccess, setPageAccess }),
    [pageAccess]
  );

  /**
   * @typedef {Object} AnalysisStepsView an object to contain values used to determine the contents of sidebar and analysis steppers
   * @property {number} analysis_type the analysis type of the currently active simulation
   * - 0 for NO Simulation active
   * - see TYPE_STRINGS.SIMULATION_TYPES for the other analysis type associations
   * @property {number} input_method used to account for variations in the stepper per analysis (like GTFS Import and Manual Input)
   * - 0 is default
   * - 1 is GTFS (assessment type)
   * - 2 is fleet manual (assessment type)
   * - 3 is school bus (assessment type)
   */

  /** @type {[ AnalysisStepsView, React.Dispatch<React.SetStateAction<AnalysisStepsView>>]} */
  const [analysisStepsView, setAnalysisStepsView] = useState({
    analysis_type: 0,
    input_method: 0,
  });

  const analysisStepsViewMemo = useMemo(
    () => ({ state: analysisStepsView, setState: setAnalysisStepsView }),
    [analysisStepsView]
  );

  /** contains the image logo image source url */
  const [logo, setLogo] = useState("");

  //defines each user's access permissions (all fields are listed here, to be used as reference; they are all be overwritten on each site reload)
  const [accessRights, setAccessRights] = useState({ ...defaultAccessRights });

  const [organizationId, setOrganizationId] = useState(""); //represents the organization selected from top-right menu
  const [organizationLookup, setOrganizationLookup] = useState({});

  const organizationMemo = useMemo(
    () => ({
      selectedOrg: organizationId,
      setSelectedOrg: setOrganizationId,
      lookup: organizationLookup,
      setLookup: setOrganizationLookup,
    }),
    [organizationId, organizationLookup]
  );

  useEffect(() => {
    let isMounted = true;
    const headers = { Authorization: `Token ${UseAuth("get")}` };
    //fetches and sets branded image logo, if there is one
    fetch(`${logoURL}?subdomain=${getSubdomain()}`, { method: "GET", headers })
      .then((response) => {
        if (response.ok) {
          response
            .json()
            .then(({ data }) =>
              setLogo(process.env.REACT_APP_BACKEND_LOGO_URL + data)
            );
        } else setLogo(img);
      })
      .catch(() => setLogo(img));

    function getAccessRights() {
      //fetches access rights
      fetch(accessRightsURL, { method: "GET", headers })
        .then((response) => {
          if (response.ok) {
            response
              .json()
              .then((resJson) => setAccessRights(resJson.data))
              .catch((e) =>
                console.log(
                  "datacontext provider failed to parse accessrights json",
                  e
                )
              );
          } else {
            console.log(response);
            setAccessRights({ ...defaultAccessRights });
            //sign the user out if they don't have access rights
            UseAuth("remove");
          }
        })
        .catch(() => {
          setAccessRights({ ...defaultAccessRights });
          //sign the user out if they don't have access rights
          setTimeout(() => {
            //wait a second, in the event that it was caused by rapid-refreshing
            if (isMounted) {
              UseAuth("remove");
              alert("Failed to fetch access rights, auto-signing user out");
            }
          }, [100]);
        });

      getLocalData("simulation").then(({ data, input }) => {
        if (data?.analysis_type)
          setAnalysisStepsView({
            analysis_type: data.analysis_type,
            input_method: input?.input_method || 0,
          });
      });
    }

    if (UseAuth("get")) getAccessRights();

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <DataContext.Provider
      value={{
        pageAccessMemo,
        analysisStepsViewMemo,
        organizationMemo,
        accessRights,
        logo,
        setLogo,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};
export default DataContextProvider;
