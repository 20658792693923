import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Button, Container, Menu, Tooltip } from "@mui/material";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { useContext, useEffect, useMemo, useState } from "react";
import { SnackBarContext } from "../../contexts/snackBarContext";
import { customerURL, partnerURL } from "../../static/constants/backendRoutes";
import materialReactTableOptions from "../../static/constants/defaultMaterialReactTableOptions";
import UseAuth from "../auth/useAuth";
import { errorHandler } from "../utils";
import { ColoredCircle } from "./defaultDialog";
import LicensingOptions from "./licensesOptions";

/**
 *
 * @param {Object} param0
 * @param {import("material-react-table").MRT_Row<never>} row
 */
function RowActionsDropdown({ row }) {
  /** @type {[EventTarget & HTMLButtonElement]} */
  const [anchorEl, setAnchorEl] = useState(null);
  const isOpen = Boolean(anchorEl);

  const onClose = () => setAnchorEl(null);

  return (
    <>
      <Tooltip title="Open Options">
        <Button
          variant="outlined"
          className="btn"
          onClick={(e) => setAnchorEl(e.currentTarget)}
          endIcon={
            isOpen ? (
              <ExpandLess sx={{ pointerEvents: "none" }} />
            ) : (
              <ExpandMore sx={{ pointerEvents: "none" }} />
            )
          }
        >
          Options
        </Button>
      </Tooltip>
      <Menu anchorEl={anchorEl} open={isOpen} onClose={onClose}>
        <LicensingOptions onClose={onClose} row={row} />
      </Menu>
    </>
  );
}

export default function LicensesView() {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const { snackBarElement } = useContext(SnackBarContext);

  useEffect(() => {
    function fetchData() {
      const headers = {
        Authorization: `Token ${UseAuth("get")}`,
        "Content-Type": "application/json",
      };

      const { id: OrganizationId } = UseAuth("get_details");

      /** fetch all partner-level organizations */
      const partnerPromise = fetch(
        `${partnerURL}?parent_id=${OrganizationId}`,
        { method: "GET", headers }
      ).then((response) => {
        if (response.ok) return response.json().then(({ data }) => data);
        //do not display error if cause is insufficient permissions
        else if (response.status == 403) return [];
        else {
          errorHandler(response, snackBarElement, "Failed to get partners");
          return [];
        }
      });

      /** fetch all customer-level organizations */
      const customerPromise = fetch(
        `${customerURL}?parent_id=${OrganizationId}`,
        { method: "GET", headers }
      ).then((response) => {
        if (response.ok) return response.json().then(({ data }) => data);
        //do not display error if cause is insufficient permissions
        else if (response.status == 403) return [];
        else {
          errorHandler(response, snackBarElement, "Failed to get customers");
          return [];
        }
      });

      /** combine partner and customer organization lists */
      Promise.all([partnerPromise, customerPromise])
        .then(([partners, customers]) =>
          setData(
            partners
              .filter((el) => el.is_active)
              .map((el) => ({ ...el, parent: "Site", type: "Partner" }))
              .concat(
                customers
                  .filter((el) => el.is_active)
                  .map((el) => ({ ...el, type: "Customer" }))
              )
              .sort((a, b) => b.id - a.id)
          )
        )
        .catch((e) => {
          console.log(e);
          snackBarElement?.current?.displayToast(
            "Failed to get organizations",
            "warning",
            5000
          );
        })
        .finally(() => setIsLoading(false));
    }

    fetchData();
  }, []);

  const columns = useMemo(
    /** @returns {import("material-react-table").MRT_ColumnDef<never> []} */
    () => [
      {
        header: "Status",
        accessorKey: "is_active",
        Cell: ({ cell }) => (
          <ColoredCircle
            style={{ marginLeft: "1rem" }}
            color={{ true: "green", false: "red" }[cell.getValue()]}
            label={
              "Organization: " +
              { true: "Active", false: "Inactive" }[cell.getValue()]
            }
          />
        ),
      },
      { header: "Organization Name", accessorKey: "name" },
      { header: "Type", accessorKey: "type" },
      { header: "Parent", accessorKey: "parent" },
      {
        header: "Licensing Options",
        id: "options",
        Cell: ({ row }) => <RowActionsDropdown row={row} />,
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    ...materialReactTableOptions(),
    data,
    columns,

    state: { isLoading },

    //miscellaneous options
    enableColumnPinning: false,
    enableHiding: false,
    // muiTableHeadCellProps: { align: "center" },
    // muiTableBodyCellProps: { align: "center" },
  });

  return (
    <Container fixed maxWidth="xl">
      <br /> <br />
      <MaterialReactTable table={table} />
    </Container>
  );
}
